import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { useLocation } from "react-router-dom";
import {toCreasedNormals} from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import * as U from '../components/Crown Component/utils'


const MeshManipulation = () => {
  const mountRef = useRef(null);
  const location = useLocation();
  const { prep, crown } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [sceneObjects, setSceneObjects] = useState({});
  const [wireframeMode, setWireframeMode] = useState(false);
  let scene;
  const vertexShaderCrown = `attribute vec3 thickness_pair; // Declare thickness_pair as an attribute
attribute vec3 thickness_anta;
varying vec3 vNormal;
varying vec3 vPosition;
varying vec3 vLightDirection;
varying vec3 vViewPosition;
varying vec3 vThicknessPair;  // Pass thickness_pair to fragment shader
varying vec3 vThicknessAnta;
uniform vec3 lightPosition;

void main() {
    vNormal = normalize(normalMatrix * normal);
    vPosition = (modelMatrix * vec4(position, 1.0)).xyz;
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    vViewPosition = -mvPosition.xyz;
    vLightDirection = normalize(lightPosition - mvPosition.xyz);
    
    // Pass the thickness_pair attribute to the fragment shader
    vThicknessPair = thickness_pair;  // Assign the attribute to a varying
    vThicknessAnta = thickness_anta;
    
    gl_Position = projectionMatrix * mvPosition;
}
`;
const fragmentShaderCrown = `
    uniform vec3 lightColor;
    uniform vec3 ambientLightColor;
    uniform vec3 diffuse;
    uniform vec3 specular;
    uniform float shininess;
    uniform float opacity;
    uniform float roughness;
    uniform float translucency;
    uniform vec3 crownAxis;
    
    uniform float brushradius;
    uniform vec3 uHoverPosition;
    
    varying vec3 vNormal;
    varying vec3 vPosition;
    varying vec3 vLightDirection;
    varying vec3 vViewPosition;
    varying vec3 vThicknessPair;
    varying vec3 vThicknessAnta;
    
    float distance_space(vec3 p0, vec3 p1) {
        return length(p1 - p0);
    }

    vec3 getCustomColor(float dist) {
        if (dist < 0.3) return vec3(1.0, 0.0, 0.0); // Red
        else if (dist < 0.4) return vec3(1.0, 1.0, 0.0); // Yellow
        else if (dist < 0.5) return vec3(0.0, 1.0, 0.0); // Green
        else if (dist < 0.6) return vec3(0.0, 0.0, 1.0); // Blue
    }

    vec3 getCustomColorIntrusion(float dist) {
        if (dist > 0.4) return vec3(1.0, 0.0, 0.0); // Red
        else if (dist > 0.3) return vec3(1.0, 1.0, 0.0); // Yellow
        else if (dist > 0.2) return vec3(0.0, 1.0, 0.0); // Green
        else if (dist > 0.1) return vec3(0.0, 0.0, 1.0); // Blue
    }

    void main() {
        vec3 normal = normalize(vNormal);
        if (!gl_FrontFacing) normal = -normal;

        vec3 lightDir = normalize(vLightDirection);
        vec3 viewDir = normalize(cameraPosition - vPosition);
        
        float lambertian = max(dot(normal, lightDir), 0.0);
        vec3 diffuseColor = lambertian * diffuse;
        
        vec3 halfwayDir = normalize(lightDir + viewDir);
        float specAngle = max(dot(normal, halfwayDir), 0.0);
        float specularIntensity = pow(specAngle, shininess);
        vec3 specularColor = specular * specularIntensity;
        
        float sss = translucency * max(dot(-viewDir, lightDir), 0.0);
        float ao = max(0.1, dot(normal, viewDir));
        vec3 ambient = ambientLightColor * diffuse * ao;
        vec3 color = ambient + diffuseColor + specularColor + sss * lightColor;

        float dist = distance_space(vPosition, vThicknessPair);
        float anta_dist = distance_space(vPosition, vThicknessAnta);

        // Apply custom discrete coloring based on distance thresholds
        if (dist < 0.6 ) {
            color = getCustomColor(dist);
        }

        // --- Restore Hover Ring Effect ---
        float distanceFromHover = distance_space(vPosition, uHoverPosition);
        if (brushradius > distanceFromHover && distanceFromHover > (brushradius - brushradius / 15.0)) {
            // Red ring around the hover position
            color = mix(color, vec3(1.0, 0.0, 0.0), 0.5);
        }

        gl_FragColor = vec4(color, opacity);
    }
`;

  useEffect(() => {
    if (!prep && !crown) {
      console.warn("No STL files provided");
      setLoading(false);
      return;
    }

    scene = new THREE.Scene();
    scene.background = new THREE.Color(0xf0f0f0);

    const camera = new THREE.PerspectiveCamera(
      65,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(-40, -40, 40);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.shadowMap.enabled = true;
    mountRef.current.appendChild(renderer.domElement);

    const loader = new STLLoader();
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;

   function loadMesh(loader, file, materialOptions, fileName) {
       function generateIndex(geometry,inner_surface,allowed_points) {
         const positionAttr = geometry.attributes.position;
         const positions = positionAttr.array; // Flattened array of vertex positions
         const uniqueVertices = [];
         const uniqueNormals = [];
         const index = [];
         const vertexMap = new Map(); // To track unique vertices  
         // Helper function to generate a key for a vertex
         const vertexKey = (x, y, z) => `${x.toFixed(6)},${y.toFixed(6)},${z.toFixed(6)}`;
     
         for (let i = 0; i < positions.length; i += 3) {
             const x = positions[i];
             const y = positions[i + 1];
             const z = positions[i + 2];
             const key = vertexKey(x, y, z);
     
             if (vertexMap.has(key)) {
                 // If vertex already exists, push its index
                 index.push(vertexMap.get(key));
             } else {
                 // Otherwise, add it to the unique vertices and map
                 uniqueVertices.push(x, y, z);
                 uniqueNormals.push(geometry.attributes.normal.array[i], geometry.attributes.normal.array[i + 1], geometry.attributes.normal.array[i + 2]);
                 const newIndex = uniqueVertices.length / 3 - 1; // Calculate index
                 vertexMap.set(key, newIndex);
                 index.push(newIndex);
             }
         }
   
     
         // Update geometry attributes
         geometry.setAttribute(
             'position',
             new THREE.Float32BufferAttribute(uniqueVertices, 3)
         );
         geometry.setAttribute(
             'normal',
             new THREE.Float32BufferAttribute(uniqueNormals, 3))
         geometry.setIndex(index);
   
         const vert_pair = U.Heatmapverts(inner_surface,geometry,allowed_points);
   
   
         geometry.setAttribute("thickness_pair",new THREE.Float32BufferAttribute(vert_pair, 3));
   
   
         return geometry;
     }
       return new Promise((resolve, reject) => {
         loader.load(
           URL.createObjectURL(file),
           (geometry) => {
          //  geometry = toCreasedNormals(geometry, (90 / 180) * Math.PI);
          //  if(fileName.startsWith("crown")){
          //   //  geometry = generateIndex(geometry,this.inner_surface,this.allowed_points);
          //    geometry.computeVertexNormals();
   
          //      // const geo = U.parseGeometry(geometry);
          //      // console.log("geo", geo)
          //  }
           const materialUniforms = {
             diffuse: { value: new THREE.Color(fileName.startsWith("crown") ? 0xecf0f1: 0xd6c5b5) }, // Whitish crown, beige base
             specular: { value: new THREE.Color(fileName.startsWith("crown") ? 0x999999 : 0xaaaaaa) }, // Reduced specular for grooves
             shininess: { value: fileName.startsWith("crown") ? 150 : 50 }, // Softer shininess for crown
             roughness: { value: fileName.startsWith("crown") ? 3 : 0.8 }, // Moderate roughness for better groove contrast
             opacity: { value: 1.0 }, // Fully opaque
             translucency: { value: fileName.startsWith("crown") ? 0.1 : 0.4 }, // Minimal translucency for the crown
             clearcoat: { value: fileName.startsWith("crown") ? 0.00001 : 0.1 }, // Minimal clearcoat for the crown
             clearcoatRoughness: { value: fileName.startsWith("crown") ? 1 : 0.2 }, // Slightly rougher clearcoat for crown
             ambientLightColor: { value: new THREE.Color(0x707070).multiplyScalar(fileName.startsWith("crown") ? 0 : 1) }, // Neutral ambient light
             transparent: true,
             brushradius: { value: 0.0 },
            //  crownAxis: { value: fileName.startsWith("crown") ? new THREE.Vector3(this.crown_axis[0],this.crown_axis[1],this.crown_axis[2]) : new THREE.Vector3(0, 0, 0) },
             uHoverPosition: { value: new THREE.Vector3(-1000, -1000, -1000) },
             // isThick: { value: true },
           };
           
           
             
             const material = new THREE.ShaderMaterial({
               vertexShader: fileName.startsWith("crown")? vertexShaderCrown :null,
               fragmentShader: fileName.startsWith("crown")? fragmentShaderCrown :  null,
               uniforms: materialUniforms,
               transparent: true,
     side: THREE.DoubleSide,
     vertexColors: true,
             });
   
             const mesh = new THREE.Mesh(geometry, material);
            //  const anta_mesh = this.scene.getObjectByName('anta');
               // if((fileName.startsWith("crown")) && anta_mesh){
               //   const verts = U.upper_heatmap(geometry, anta_mesh.geometry);
               //   geometry.setAttribute('thickness_anta', new THREE.Float32BufferAttribute(verts[0], 3));
               // }
             mesh.scale.set(1, 1, 1);
             mesh.castShadow = true;
             mesh.receiveShadow = true;
             mesh.name = fileName;
             scene.add(mesh);
  
   
             if (fileName.startsWith('crown_')) {
               // Existing changes
               mesh.material.depthWrite = true;
               mesh.renderOrder = -1;
             }
             resolve(mesh);
           },
           undefined,
           (error) => {
             reject(error);
           }
         );
       });
     }

    let objects = {};

    const loadMeshes = async () => {
      try {
        const promises = [];
        const STLLoaderInstance = new STLLoader();
        // if (prep) promises.push(loadMesh(prep, 0x00ff00, "prep"));
        if (crown) promises.push(loadMesh(STLLoaderInstance,crown, 0xd6c5b5, "crown_1"));

        const meshes = await Promise.all(promises);
        meshes.forEach((mesh) => {
          scene.add(mesh);
          objects[mesh.name] = mesh;
        });

        setSceneObjects(objects);
      } catch (error) {
        console.error("Error loading STL files:", error);
      } finally {
        setLoading(false);
      }
    };

    loadMeshes();

    // Lighting Setup
    const ambientLight = new THREE.AmbientLight(0xffffff, 1.4)
    ambientLight.position.set(50, -50, 50);
    scene.add(ambientLight);

    

    const directionalLight = new THREE.DirectionalLight(0xffffff, 2.5);
    directionalLight.position.set(50, -50, 50);
    directionalLight.castShadow = true;
    scene.add(directionalLight);

    const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1.5);
    directionalLight1.position.set(50, 10, -90);
    directionalLight1.castShadow = true;
    scene.add(directionalLight1);



    // const pointLight1 = new THREE.PointLight(0xffffff, 1.5, 100);
    // pointLight1.position.set(-30, -30, 20);
    // scene.add(pointLight1);

    // const pointLight2 = new THREE.PointLight(0xffffff, 1.5, 100);
    // pointLight2.position.set(30, 30, -20);
    // scene.add(pointLight2);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      mountRef.current.removeChild(renderer.domElement);
    };
  }, [prep, crown, wireframeMode]);

  return (
    <div>
      {loading && <div>Loading STL files...</div>}
      <div ref={mountRef} />
      <button
        onClick={() => setWireframeMode((prev) => !prev)}
        style={{ position: "absolute", top: 10, left: 10, padding: "10px" }}
      >
        Toggle Wireframe
      </button>
    </div>
  );
};

export default MeshManipulation;